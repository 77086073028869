import { appProvider } from '@/app-provider'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { getFollowStatsNumberAfterSync, getPostStatsNumberAfterSync } from '@/helper/utils'
import { ProfileModel } from '@/models/profile-model'
import { apiService } from '@/services/api-services'
import { dispatcher, FollowingProfile, FollowTypeEnum, PostActionModel } from '@/stores/dispatcher'
import { localData } from '@/stores/local-data'
import { walletStore } from '@/stores/wallet-store'
import { action, computed, IReactionDisposer, observable, reaction } from 'mobx'
import { asyncAction } from 'mobx-utils'
import moment from 'moment'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

export class ProfileViewModel {
  @observable userProfile?: ProfileModel
  //Avatar
  @observable isEnableModifyProfile = false
  @observable avatarFileSource = ''
  @observable avatarFileInput?: File = undefined
  @observable avatarUploading = false

  @observable coverImageFileSource = ''
  @observable coverImageFileInput?: File = undefined
  @observable coverImageUploading = false

  // Is other profile
  @observable followLoading = false
  @observable lastPost?: any

  private _unsubcrible = new Subject()
  private _disposers: IReactionDisposer[]

  constructor() {
    dispatcher.$profileChanged.pipe(takeUntil(this._unsubcrible)).subscribe((profile) => {
      this.syncFollowProfile(profile)
    })
    dispatcher.$postChanged.pipe(takeUntil(this._unsubcrible)).subscribe((postAction) => {
      this.syncPostAction(postAction)
    })

    this._disposers = [
      reaction(
        () => walletStore.userProfile,
        (userProfile) => {
          if (userProfile?._id) {
            this.fetchUserInfo()
          }
        }
      ),
    ]
  }

  @action syncFollowProfile(profile: FollowingProfile) {
    if (this.userProfile?._id === profile.to) {
      this.userProfile = {
        ...this.userProfile,
        isFollowing: profile.followState,
        loading: false,
        totalFollowers: getFollowStatsNumberAfterSync(this.userProfile.totalFollowers, profile.followState),
      }
    }
    if (this.userProfile?._id === profile.from) {
      if (profile.type === FollowTypeEnum.user) {
        this.userProfile = {
          ...this.userProfile,
          loading: false,
          totalFollowings: getFollowStatsNumberAfterSync(this.userProfile.totalFollowings, profile.followState),
        }
      } else if (profile.type === FollowTypeEnum.tag) {
        this.userProfile = {
          ...this.userProfile,
          loading: false,
          totalTagFollowings: getFollowStatsNumberAfterSync(this.userProfile.totalTagFollowings, profile.followState),
        }
      }
    }
  }

  @action syncPostAction(postAction: PostActionModel) {
    this.userProfile = {
      ...this.userProfile,
      totalPosts: getPostStatsNumberAfterSync(this.userProfile?.totalPosts, postAction.type),
    }
  }
  @asyncAction *fetchUserInfo() {
    try {
      const userId = appProvider.router.currentRoute.params.userId
      const userProfiles = yield apiService.profiles.find({ unique_id: userId, _limit: 1 })
      if (userProfiles[0]) {
        this.userProfile = userProfiles[0]
        if (!this.isMyProfile) this.checkFollowingStatus()
        this.fetchLastPost()
      } else {
        snackController.commonError('Can not find user profile')
      }
    } catch (error) {
      snackController.commonError(error)
    }
  }

  @asyncAction *changeAvatarInput(event) {
    const file: File = event.target?.files[0]
    if (!file) return
    const cacheAvatarFileSource = this.avatarFileSource
    const cacheAvatarFileInput = this.avatarFileInput
    this.avatarFileSource = URL.createObjectURL(file)
    this.avatarFileInput = file

    try {
      this.avatarUploading = true
      const fileS3 = yield apiService.posts.upload(file)
      const updatedProfile = yield apiService.profiles.updateUserProfile({
        id: walletStore?.userProfile?._id,
        avatar: fileS3[0]?.id,
      })

      this.userProfile = updatedProfile
      //   this.setProfileInfo(updatedProfile)
      snackController.success('Update avatar successfully')
    } catch (e) {
      snackController.commonError(e)
      this.avatarFileSource = cacheAvatarFileSource
      this.avatarFileInput = cacheAvatarFileInput
    } finally {
      this.avatarUploading = false
    }
  }

  @asyncAction *changeCoverImageInput(event) {
    const file: File = event.target?.files[0]
    if (!file) return
    const cacheCoverImageFileSource = this.coverImageFileSource
    const cacheCoverImageFileInput = this.coverImageFileInput
    this.coverImageFileSource = URL.createObjectURL(file)
    this.coverImageFileInput = file

    try {
      this.coverImageUploading = true
      const fileS3 = yield apiService.posts.upload(file)
      const updatedProfile = yield apiService.profiles.updateUserProfile({
        id: walletStore?.userProfile?._id,
        coverImage: fileS3[0]?.id,
      })

      this.userProfile = updatedProfile
      //   this.setProfileInfo(updatedProfile)
      snackController.success('Update coverImage successfully')
    } catch (e) {
      snackController.commonError(e)
      this.coverImageFileSource = cacheCoverImageFileSource
      this.coverImageFileInput = cacheCoverImageFileInput
    } finally {
      this.coverImageUploading = false
    }
  }

  @asyncAction *checkFollowingStatus() {
    try {
      const status = yield apiService.userFollows.count({
        follower: walletStore.userProfile?._id,
        follow: this.userProfile?._id,
      })
      if (status) this.userProfile = { ...this.userProfile, isFollowing: true }
      else this.userProfile = { ...this.userProfile, isFollowing: false }
    } catch (error) {
      snackController.commonError(error)
    }
  }

  @asyncAction *fetchLastPost() {
    try {
      const lastPost = yield apiService.posts.find({
        profile: walletStore.userProfile?._id,
        status: 'public',
        _limit: 1,
        _sort: 'createdAt:desc',
      })
      if (lastPost.length) this.lastPost = lastPost[0]
    } catch (error) {
      snackController.commonError(error)
    }
  }

  @asyncAction *followUser(item: any) {
    // if (!walletStore.verifyUserAction()) return
    try {
      item.loading = true
      yield apiService.userFollows.followUser({ follow: item.id })
      item.isFollowing = true
    } catch (error) {
      snackController.commonError(error)
    } finally {
      item.loading = false
    }
  }

  @asyncAction *unFollowUser(item: any) {
    // if (!walletStore.verifyUserAction()) return
    try {
      item.loading = true
      yield apiService.userFollows.unFollowUser({ follow: item.id })
      item.isFollowing = false
    } catch (error) {
      snackController.commonError(error)
    } finally {
      item.loading = false
    }
  }

  @computed get avatar() {
    return this.userProfile?.avatar?.url
  }

  @computed get defaultAvatar() {
    const defaultAvatarIndex = this.userProfile?.defaultAvatarIndex
    return defaultAvatarIndex != undefined
      ? `${process.env.VUE_APP_API_ENDPOINT}/uploads/profile-avatars/profile-avatar-${defaultAvatarIndex}.png`
      : undefined
  }

  @computed get coverImage() {
    return this.userProfile?.coverImage?.url
  }

  @computed get userName() {
    return this.userProfile?.username
  }
  @computed get userSocials() {
    if (!this.userProfile) return []
    const socials = [] as any
    const { discordUser, facebookUser, youtubeUser, twitterUser, githubUser, redditUser } = {
      ...this.userProfile,
    }
    if (discordUser && discordUser.username && discordUser.id)
      socials.push({
        link: `https://discordapp.com/users/${discordUser.id}`,
        username: discordUser.username,
        type: 'discord',
      })
    if (githubUser && githubUser.id)
      socials.push({
        link: `https://github.com/${githubUser.login}`,
        username: githubUser.login,
        type: 'github',
      })
    if (redditUser && redditUser.id)
      socials.push({
        link: `https://www.reddit.com/user/${redditUser.name}`,
        username: redditUser.name,
        type: 'reddit',
      })
    if (facebookUser && facebookUser.id)
      socials.push({
        link: facebookUser.link,
        username: facebookUser.name,
        type: 'facebook',
      })
    if (twitterUser && twitterUser.id)
      socials.push({
        link: `https://twitter.com/${twitterUser.screen_name}`,
        username: twitterUser.name,
        type: 'twitter',
      })
    if (youtubeUser) {
      const channelItem = youtubeUser?.items?.find((item) => item.kind === 'youtube#channel' && item.id)
      if (channelItem.id) {
        socials.push({
          link: `https://www.youtube.com/channel/${channelItem.id}`,
          username: 'Youtube channel',
          type: 'youtube',
        })
      }
    }
    return socials
  }

  @computed get walletAddress() {
    return this.userProfile?.walletAddress
  }

  @computed get shortWalletAddress() {
    if (!this.userProfile?.walletAddress) return ''
    return (
      this.userProfile?.walletAddress.substr(0, 15) +
      '...' +
      this.userProfile?.walletAddress.substr(this.userProfile?.walletAddress.length - 8)
    )
  }

  @computed get yourselfDescription() {
    return this.userProfile?.yourselfDescription
  }

  @computed get accountName() {
    return this.userProfile?.unique_id
  }

  @action shareProfile() {
    navigator.clipboard.writeText(`${process.env.VUE_APP_HOST}profile/${this.userProfile?.unique_id}`)
    snackController.success('Copied to clipboard')
  }

  @computed get maskedEmail() {
    const email = this.userProfile?.email
    if (email) {
      const [name, domain] = email.split('@')
      const { length: len } = name
      const maskedName = name[0] + '...' + name[len - 1]
      const maskedEmail = maskedName + '@' + domain
      return maskedEmail
    }
    return ''
  }

  @computed get isMyProfile() {
    return walletStore.userProfile?._id === this.userProfile?._id
  }

  @computed get joined() {
    return moment(this.userProfile?.createdAt).format('MMM,DD, YYYY')
  }

  @computed get lastSeen() {
    return localData.lastSeen || moment().format('MMM,DD, YYYY')
  }

  @computed get profileLastPost() {
    return moment(this.lastPost?.createdAt).format('MMM,DD, YYYY')
  }

  @computed get badges() {
    return this.userProfile?.badges
  }

  get profileMenu() {
    if (!this.isMyProfile) {
      return [
        { label: 'Posts', icon: 'activity-post-ic.svg', path: 'posts' },
        { label: 'Comments', icon: 'activity-comment-ic.svg', path: 'comments' },
        { label: 'Likes', icon: 'activity-like-ic.svg', path: 'likes' },
      ]
    } else {
      return [
        { label: 'Posts', icon: 'activity-post-ic.svg', path: 'posts' },
        { label: 'Comments', icon: 'activity-comment-ic.svg', path: 'comments' },
        { label: 'Likes', icon: 'activity-like-ic.svg', path: 'likes' },
        { label: 'Bookmark', icon: 'activity-bookmark-ic.svg', path: 'bookmark' },
        { label: 'Draft', icon: 'activity-draft-ic.svg', path: 'draft' },
        { label: 'Following', icon: 'activity-following-ic.svg', path: 'followings' },
        { label: 'Notifications', icon: 'activity-notification-ic.svg', path: 'notifications' },
      ]
    }
  }

  destroy() {
    this._unsubcrible.next()
    this._unsubcrible.complete()
    this._disposers.forEach((d) => d())
  }
}

export const profileController = new ProfileViewModel()
