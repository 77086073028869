









































































































































import { AppProvider } from '@/app-provider'
import { createPostController } from '@/modules/common/dialogs/create-post/create-post-controller'
import { Observer } from 'mobx-vue'
import { Component, Inject, Vue, Watch } from 'vue-property-decorator'
import { profileController } from '../viewmodels/profile-viewmodel'

@Observer
@Component({
  components: {
    'most-active-user-section': () => import('@/modules/common/components/section/most-active-user-section.vue'),
    'most-active-dao-section': () => import('@/modules/common/components/section/most-active-dao-section.vue'),
    'user-information': () => import('../components/user-information.vue'),
  },
})
export default class Profile extends Vue {
  @Inject({}) providers!: AppProvider

  index = 0
  profileController = profileController
  createPostController = createPostController
  getTargetRoute(tab: string) {
    this.index = tab === 'summary' ? 0 : tab === 'activity' ? 1 : 2
    const currentParams = this.$router.currentRoute.params.userId
    const targetRoute = `/profile/${currentParams}/${tab}`
    return targetRoute
  }

  @Watch('$route.params.userId', { immediate: true }) async userChange() {
    await this.profileController.fetchUserInfo()
  }

  hideFloatingButton = false

  @Watch('$vuetify.breakpoint.width', { immediate: true }) onHideFloatingButton(width: number) {
    if (width >= 737) {
      this.hideFloatingButton = true
      this.isOverlay = false
      return
    }
    this.hideFloatingButton = false
  }

  isOverlay = false

  overlay() {
    this.isOverlay = !this.isOverlay
  }

  onPostNow() {
    this.isOverlay = false
    this.createPostController.show(true)
  }

  onCreateDao() {
    this.isOverlay = false
    this.redirectCreateDao()
  }

  redirectCreateDao = () => {
    this.$router.push('/community-organize/dao/create')
  }
}
